<template>
  <a-card>
    <a-row
      class="mb-3"
      :gutter="16"
    >
      <a-col><p class="title">Enter Instagram Account URL</p></a-col>
      <a-col :flex="1">
        <a-row
          justify="end"
          :gutter="16"
          ><a-col
            ><FacebookLogin
              source="instagram"
              scopes="pages_show_list,pages_manage_posts,instagram_basic,public_profile"
              app-id="1713627038673709"
              @authenticated="loadData"
            ></FacebookLogin></a-col
        ></a-row>
      </a-col>
    </a-row>
    <a-row
      type="flex"
      :gutter="16"
    >
      <a-col flex="1">
        <a-form
          ref="ruleForm"
          :model="form"
          :rules="rules"
          @submit="reValidate"
        >
          <a-form-item
            name="resolveUrl"
            :validate-first="true"
          >
            <a-input
              v-model:value="form.resolveUrl"
              type="text"
              placeholder="https://www.instagram.com/insightera_th/"
              autocomplete="off"
            />
          </a-form-item>
        </a-form>
      </a-col>
      <a-col>
        <a-button
          v-if="validateInfos.resolveUrl.validateStatus == 'success'"
          type="primary"
          size="default"
          :loading="isResolve"
          @click="resolveInstagramUser"
        >
          Resolve
        </a-button></a-col
      >
    </a-row>
    <AddInstagramUser
      v-if="userInfo"
      :user-info="userInfo"
      :visible="addUserVisible"
      @close="addUserVisible = false"
      @success="addUserSuccess"
    ></AddInstagramUser>
  </a-card>
</template>

<script>
import { ref, computed, reactive, watch } from 'vue';
import AddInstagramUser from './AddInstagramUser.vue';
import api from '@/services/api';
import { useStore } from 'vuex';
import { Form, notification } from 'ant-design-vue';
import FacebookLogin from '@/components/Facebook/FacebookLogin.vue';

export default {
  components: { AddInstagramUser, FacebookLogin },
  setup() {
    const useForm = Form.useForm;
    const store = useStore();
    const userInfo = ref({});
    const addUserVisible = ref(false);
    const instagramAccounts = computed(
      () => store.getters['sourceSetting/instagramAccounts']
    );
    const isResolve = ref(false);
    const ruleForm = ref(0);
    const form = reactive({
      resolveUrl: '',
    });

    const checkExist = async () => {
      if (form.resolveUrl) {
        try {
          let result = await api.resolveInstagramUser(form.resolveUrl);

          let isExist = instagramAccounts.value.filter(
            (v) => v.id === result.message.userId
          )[0];
          if (isExist) return Promise.reject('Added url is already exist');
          else Promise.resolve();
        } catch (error) {
          return Promise.resolve();
        }
      } else {
        return Promise.resolve();
      }
    };

    const rules = reactive({
      resolveUrl: [
        { required: true, message: 'Please input user url', trigger: 'change' },
        {
          pattern: new RegExp('https://www.instagram.com/.+/?', 'g'),
          message: 'URL is incorrect or not exist',
          trigger: 'change',
        },
        {
          validator: checkExist,
          trigger: 'change',
        },
      ],
    });

    const resolveInstagramUser = async () => {
      isResolve.value = true;
      try {
        let result = await api.resolveInstagramUser(form.resolveUrl);

        userInfo.value = {
          accountId: store.state.account.brand,
          link: result.message.link,
          name: result.message.fullName,
          id: result.message.userId,
          profilePictureURL: result.message.profilePictureURL,
        };
        addUserVisible.value = true;
      } catch (error) {
        if (error.response.data != null && error.response.data.message) {
          notification.error({
            message: 'Error',
            description: `${error.response.data.message}`,
          });
        } else {
          notification.error({
            message: 'Error',
            description: `Resolve ${form.resolveUrl} failed.`,
          });
        }
      }
      isResolve.value = false;
    };

    const addUserSuccess = async () => {
      form.resolveUrl = '';
      addUserVisible.value = false;
      await store.dispatch('sourceSetting/getInstagramAccounts');
      setTimeout(() => {
        userInfo.value = null;
      }, 200);
    };

    const { validate, validateInfos } = useForm(form, rules);

    watch(
      () => form.resolveUrl,
      (url) => {
        if (!url) {
          ruleForm.value.resetFields();
        }
      }
    );

    const loadData = async () => {
      await store.dispatch('sourceSetting/getInstagramAccounts');
    };

    const reValidate = () => {
      validate();
    };

    return {
      reValidate,
      ruleForm,
      rules,
      isResolve,
      form,
      userInfo,
      addUserVisible,
      resolveInstagramUser,
      addUserSuccess,

      validateInfos,
      loadData,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: '#272B41';
}

.text-danger {
  font-family: DM Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff4d4f;
}

.mt-6 {
  margin-top: 6px;
}
</style>
