<template>
  <a-modal
    title="Youtube Channel"
    :visible="visible"
    @cancel="close"
  >
    <a-card class="page-info">
      <a-row
        type="flex"
        :gutter="20"
      >
        <a-col
          ><a-avatar
            :size="47"
            :style="{ verticalAlign: 'middle' }"
            :src="channelInfo.profilePictureURL"
          ></a-avatar
        ></a-col>
        <a-col class="col-center">
          <h3>{{ channelInfo.name }}</h3>
          <small>Id: {{ channelInfo.id }}</small></a-col
        >
      </a-row>
    </a-card>

    <a-row class="mt-10">
      <a-col>
        <h3>Data Collection</h3>
        <a-radio-group v-model:value="selectedType">
          <a-radio
            class="radio"
            :value="true"
          >
            Matched keyword
          </a-radio>
          <a-radio
            class="radio"
            :value="false"
          >
            All messages
          </a-radio>
        </a-radio-group>
      </a-col>
    </a-row>

    <template #footer>
      <div class="action-button-modal text-right">
        <a-button
          size="large"
          type="default"
          class="cancel-button mr-8"
          :outlined="true"
          @click="close"
        >
          Cancel
        </a-button>
        <a-button
          size="large"
          type="primary"
          class="add-button"
          @click="addChannel"
          >Add</a-button
        >
      </div>
    </template>
  </a-modal>
</template>

<script>
import { ref, toRefs } from 'vue';
import api from '@/services/api';
import { notification } from 'ant-design-vue';
export default {
  props: {
    visible: Boolean,
    channelInfo: {
      type: Object,
      default: () => {
        return {
          // accountId:"",
          // link:"",
          // name:""
          // id: ""
          // profilePictureURL:""
        };
      },
    },
  },
  emits: ['close', 'success', 'error'],
  setup(props, context) {
    const { channelInfo } = toRefs(props);
    const selectedType = ref(true);
    const resetData = () => {
      selectedType.value = true;
    };

    const close = () => {
      resetData();
      context.emit('close');
    };

    const addChannel = async () => {
      let result = await api.addYoutubeChannelByAccountId(
        channelInfo.value.accountId,
        {
          isConsiderKeyword: selectedType.value,
          link: channelInfo.value.link,
        }
      );

      if (result.code === 200) {
        notification.success({
          message: 'Success',
          description: `Channel ${channelInfo.value.name} added.`,
        });
        context.emit('success');
      } else {
        notification.error({
          message: 'Error',
          description: `Channel ${channelInfo.value.name} add failed.`,
        });
        context.emit('error');
      }
      resetData();
    };
    return { selectedType, close, addChannel };
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0px;
}

.add-button {
  border-radius: 4px;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.cancel-button {
  background: #f4f5f7;
  border-radius: 4px;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #5a5f7d;
}

.page-info {
  // box-shadow: 0px 5px 30px 0px #9299b831;
  border: 1px solid #e3e6ef;
  box-sizing: border-box;
  // filter: drop-shadow(0px 5px 30px rgba(146, 153, 184, 0.194));
  border-radius: 10px;
}

.radio {
  display: block;
  height: 30px;
  line-height: 30px;
}

.col-center {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
</style>
